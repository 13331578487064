<template>
  <v-col cols="12">
    <v-text-field
      v-model="searchValue"
      v-debounced
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-l rounded-r"
      filled
      rounded
      full-width
      placeholder="Поиск"
      clearable
      @click:clear="clearSearch"
    />
    <v-tabs
      v-model="typeIndex"
      class="my-2"
    >
      <v-tab
        v-for="(type, idx) in types"
        :key="`${type.enName}-${idx}`"
      >
        {{ type.title }}
      </v-tab>
    </v-tabs>
    <v-simple-table class="mt-2">
      <thead>
        <tr>
          <th
            v-for="(header,idx) in headers"
            :key="`h-${idx}`"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in filtered"
          :key="'type-item-' + idx"
        >
          <td>
            <text-highlight
              :queries="searchValue || ''"
              class="code-txt"
            >
              {{ item.name }}
            </text-highlight>
          </td>
          <td>
            <div class=" mt-1">
              <text-highlight :queries="searchValue || ''">
                {{ item.class_name }}
              </text-highlight>
            </div>
          </td>
          <td>
            <div class="light-txt mt-1">
              <text-highlight :queries="searchValue || ''">
                {{ item.category }}
              </text-highlight>
            </div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</template>
<script>
export default {
  data:() => ({
    searchValue:"",
    typeIndex: 0,
    types:[],
    items:[],
    headers:[
      {
        text:'Наименование',
        value:'name'
      },
      {
        text:'Класс',
        value:'class_name'
      },
      {
        text:'Перечень',
        value:'category'
      }
    ]
  }),
  computed:{
    filtered(){
      if(this.searchValue.length){

        return this.items.filter(item => {
          return !!(item?.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1)
        })
      }
      return this.items
    }
  },
  watch:{
    typeIndex:{
      handler(nv){
        if(nv !== null) this.getTypeInfo(); 
      },
    }
  },
  created(){
    this.getTypesList()
  },
  methods:{
    clearSearch(){
      this.searchValue = ""
    },
    getTitle(code){
      switch(code){
      case 'perfume': return 'Парфюмерия и косметика';
      case 'tires': return 'Шины и покрышки';
      case 'clothes': return 'Одежда и принадлежности';
      case 'shoes': return 'Обувь';
      }
    },
    getTypesList(){
      this.$store.dispatch('catalogs/getBrandCatalogTypes').then(res => {
        this.types = res.data.map(item => ({
          ...item,
          title:this.getTitle(item.enName)
        }))
        this.setTypeIndex()
        this.getTypeInfo()
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при загрузке справочника', color:'red', top:false, right: false})
      })    
    },
    setTypeIndex(){
      const q = this.$route.query
      this.typeIndex = q?.type ? this.types.findIndex(i => i.enName === q.type): null
    },
    getTypeInfo(){
      this.clearSearch()
      const {listId} = this.types[this.typeIndex || 0]
      if(!listId) return this.$snackbar({text:'Возникла ошибка при загрузке справочника', color:'red', top:false, right: false})
      this.$store.dispatch('catalogs/getTrademarksByType', {listId}).then(res => {
        this.items = res.data
      }).catch(() => {
        this.$snackbar({text:'Возникла ошибка при загрузке справочника', color:'red', top:false, right: false})
      })   
    }   
  }
}
</script>